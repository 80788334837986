.footerContainer {
  display: flex;
  width: 100%;
  background-color: black;
  height: 60px;
  justify-content: center;
  align-items: center;
  /* position: fixed; */
  /* bottom: 0; */
  opacity: 0.8;
  margin-top: 20px;
}

.footerWrapper {
  display: flex;
  width: 90%;
  justify-content: space-evenly;
  align-items: center;
}
.footerNavLink {
  color: white;
  text-decoration: none;
}
.footerCallButtonContainer {
  width: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.footerCallButton {
  background-color: #0f52ba;
  color: white;
  border: solid 1px white;
  border-radius: 25px;
  padding: 5px;
  width: 150px;
}

@media screen and (max-width: 580px) {
  .footerContainer {
    display: none;
  }
  /* .footerContainer {
    flex-direction: column;
    height: auto;
  }
  .footerWrapper {
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: 12px;
  }
  .footerCallButtonContainer {
    margin-top: 20px;
  }
  .footerNavLinkContainer {
    margin: 10px auto;
  } */
}
