/* .navContainer,
.navbar {

  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  padding-top: 20px;
  padding-bottom: 20px;
  font-weight: 800;
  align-items: center;
  color: white;
  z-index: 15;
}

.firstSection,
.secondSection {
  text-align: center;
  width: 37.5%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  font-size: 20px;
}

.navTitle {
  text-align: center;
  width: 25%;
  font-size: 40px;
}

.blackBg {
  background-color: black;
  color: white;
}
.noBg {
  background-color: transparent;
  color: black;
}
.navLinkContainer {
  text-decoration: none;
  background-color: none;
  color: white;
}
.navLink :hover {
  color: none;
} */

.navbar {
  /* justify-content: space-evenly; */
  /* background-color: black; */
  padding: 0.5rem 2rem;

  z-index: 1;
}

.navbar-light {
  background-color: none;
}

.navbar-light .navbar-nav .nav-link {
  color: white;
  /* font-family: "M PLUS Rounded 1c", sans-serif; */
  font-weight: 400;
  letter-spacing: 1px;
}

.navbar-toggler .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255,255,255, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
}

div > .nav-item:hover {
  color: #fff !important;
}

.navbar-expand-lg .navbar-collapse {
  align-items: flex-end;
  justify-content: flex-end;
}

.navbarImage {
  height: 40px;
}

@media screen and (max-width: 580px) {
  .navbar {
    padding: 0.5rem 1rem !important;
    background-color: black;
    position: fixed;
    top: 0;
    width: 100%;
  }
  .navbar-light {
    background-color: black;
  }
}
