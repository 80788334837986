.reduceWidth {
  width: auto;
}

.faqTitleContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 60px;
  margin-bottom: 60px;
}

.faqTitle {
  font-size: 30px;
  margin-bottom: 20px;
}

.faqTitleText {
  font-size: 18px;
  font-weight: 200;
}

.faqContactUsButton {
  width: 150px;
  margin-left: 5px;
  margin-right: 5px;
  height: 30px;
  border-radius: 50px;
  border: solid 2px black;
  font-size: 15px;
  color: black;
  font-weight: 800;
  letter-spacing: 2px;
  background-color: transparent;
  margin-bottom: 20px;
}

.faqContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.faqWrapper {
  width: 60%;
  display: flex;
  flex-direction: column;
}

.faqQuestionContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}

.faqQuestion {
  font-size: 30px;
  font-weight: lighter;
}

.faqAnswer {
  font-size: 20px;
  line-height: 26px;
}

.faqHr {
  /* width: 80%; */
  margin: 30px auto;
}

.faqPhotoGalleryContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.faqPhotos {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width: 580px) {
  .faqTitleContainer {
    text-align: center;
  }
  .faqTitle {
    font-size: 20px;
    margin-bottom: 20px;
    margin-top: 60px;
  }
  .faqTitleText {
    font-size: 18px;
    width: 90%;
  }
  .faqContactUsButton {
    height: 28px;
    margin-bottom: 8px;
  }
  .faqWrapper {
    width: 90%;
  }
  .faqQuestion {
    font-size: 25px;
  }
  .faqPhotos {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}
