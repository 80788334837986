a {
  text-decoration: none;
}

a :hover {
  cursor: pointer;
}
.homePageContainer {
  position: fixed;
}
.homepageWrapper {
  width: 100%;
  display: block;
  top: -105px;
  z-index: -10;
  height: 70vh;
  position: relative;
}
.navContainer {
  z-index: 1;
}

.homepageDroneBgImage {
  width: 100%;
  filter: brightness(60%);
  position: absolute;
  height: 80vh;
  object-fit: cover;
  z-index: -10;
  top: 0;
}

.homepageHeaderContent {
  display: flex;
  flex-direction: column;
  position: absolute;
  bottom: 0;
  z-index: 15;
  left: 7%;
}
.homepageHeaderContentTitle {
  font-size: 60px;
  font-weight: 800;
  color: white;
  margin-bottom: 20px;
}
.homepageHeaderContentText {
  font-size: 22px;
  /* font-weight: 100; */
  line-height: 30px;
  color: white;
  margin-bottom: 20px;
}
.getQuoteButton {
  width: 230px;
  height: 60px;
  border-radius: 50px;
  border: solid 2px black;
  font-size: 18px;
  color: white;
  font-weight: 800;
  letter-spacing: 2px;
  background-color: #25d366;
  position: fixed;
  right: 30px;
  bottom: 80px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  /* bottom: 0;
  /* margin-bottom: 20px; */
  z-index: 15;
}

.homepageGridContainer {
  display: flex;
  flex-direction: column;
  margin-top: 80px;
  text-align: center;
  width: 100%;
}

.homepageGridTitle {
  font-size: 60px;
  font-weight: 800;
  margin-bottom: 80px;
}

.homepageGridWrapper {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-evenly;
}

.homepageGridItem {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 33%;
  margin-bottom: 50px;
}

.homepageGridItemTitle {
  font-size: 30px;
  font-weight: lighter;
  letter-spacing: 1px;
  margin-bottom: 20px;
}

.homepageGridItemImage {
  width: 400px;
  height: 250px;
  margin-bottom: 20px;
  border-radius: 25px;
}
.homepageGridItemText {
  width: 80%;
  font-weight: 500;
  font-size: 20px;
  margin-bottom: 30px;
  line-height: 31px;
  letter-spacing: 1px;
}
.viewSampleMapsButton {
  width: 230px;
  height: 60px;
  border-radius: 50px;
  border: solid 2px black;
  font-size: 18px;
  color: black;
  font-weight: 800;
  letter-spacing: 2px;
  background-color: white;
  z-index: 15;
}

.blackButton {
  border: solid 2px black;
  color: black;
  width: 330px;
}

/* banner */

.homepageBannerContainer {
  display: block;
  height: 500px;
}

.homepageBannerWrapper {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 500px;
}

.homepageBannerImage {
  filter: brightness(40%);
  width: 100%;
  height: 500px;
  object-fit: cover;
}
.homepageBannerText {
  position: absolute;
  color: white;
  text-align: center;
  font-size: 50px;
  font-weight: 800;
  width: 50%;
}

.homepage3dQuarryContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.homepage3dQuarryWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 80%;
}

.homepage3dQuarryTitle {
  font-size: 60px;
  font-weight: 800;
  margin-bottom: 50px;
}
.homepage3dQuarryImage {
  min-height: 500px;
  min-width: 230px;
  width: 90%;
}

/* contact us */
.contactUsContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 60px;
}
.contactUsWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.contactUsInfoContainer {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}
.contactUsInfoWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 50%;
}
.contactUsTitle {
  font-size: 60px;
  margin-bottom: 30px;
}
.contactUsText {
  text-align: center;
  font-size: 20px;
  line-height: 28px;
  font-weight: 500;
  margin-bottom: 30px;
}
.contactUsButton {
  width: 330px;
  height: 50px;
  border-radius: 50px;
  border: solid 2px black;
  font-size: 18px;
  color: black;
  font-weight: 800;
  letter-spacing: 2px;
  background-color: transparent;
  margin-bottom: 20px;
}

/* Packages */
.packagesTitleContainer {
  text-align: center;
  font-weight: 800;
  letter-spacing: 2px;
  margin: 60px auto 30px auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.packagesTitle {
  font-size: 60px;
}
.packagesText {
  font-size: 35px;
}
.dropDown {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 160px;
}

.dropDownAgain {
  width: 90%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.dropDownContainer {
  /* border: 1px solid black; */
  border-radius: 5px;
  box-shadow: 1px 1px 10px gray;
  margin: 20px;
  width: 50%;
  /* background: #00bfff; */
  background-image: linear-gradient(135deg, #436b83, #436b83c0);
  /* opacity: 0.5; */
}

.dropDownTitleContainer {
  text-align: center;
  display: flex;
  justify-content: center;
  margin: 20px;
  margin-bottom: 10px;
}
.dropDownTitle {
  font-size: 25px;
  font-weight: 800;
  letter-spacing: 2px;
  color: white;
}
.dropDownDescriptionContainer {
  text-align: center;
  margin: 20px;
  margin-top: 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
}
.descriptionContent {
  width: 100%;
  text-align: center;
  margin: 20px auto;
  color: lightgray;
}
.dropDownContainer:hover {
  cursor: pointer;
}
.packageButtons {
  display: flex;
  justify-content: space-around;
  width: 100%;
}
.getQuote {
  width: 145px;
  height: 40px;
  border-radius: 50px;
  border: solid 1.5px black;
  font-size: 14px;
  color: white;
  font-weight: 800;
  letter-spacing: 2px;
  background-color: #25d366;
}
.viewMore {
  width: 145px;
  height: 40px;
  border-radius: 50px;
  border: none;
  color: white;
  font-size: 14px;
  font-weight: 500;
  background-color: #0345bf;
}
.descriptionTitle {
  font-size: 20px;
  font-weight: 300;
  /* letter-spacing: 1px; */
}
.dropdownDivinderContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}
.dropdownDivider {
  width: 60%;
  border: none;
  border-radius: 50px;
  height: 1px;
  background: white;
  opacity: 0.5;
  /* margin-bottom: 50px; */
}
/* cards */
.cardContainer {
  display: flex;
  justify-content: space-evenly;
  border: none;
  margin: 30px auto;
}

.cardWrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  /* justify-content: center; */
  align-items: center;
  /* border: 1px solid black; */
  border-radius: 5px;
  width: 33%;
  height: 500px;
  margin: 20px 10px;
  padding: 10px 20px;
  box-shadow: 5px 5px 10px gray;
}
.Card {
  width: 80%;
}
.cardImage {
  width: 50%;
}
.cardDivider {
  width: 60%;
  border: none;
  border-radius: 50px;
  height: 1px;
  background: black;
  opacity: 0.5;
}
.cardTitle {
  font-size: 20px;
  font-weight: 800;
  letter-spacing: 1px;
}
.cardDescription {
  line-height: 25px;
  font-size: 17px;
  color: gray;
}
/* packages */
.packageOneWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.packageOneTitle {
  text-align: center;
  font-size: 60px;
  font-weight: 800;
  letter-spacing: 1.5px;
}

.packageContentText {
  text-align: center;
}
.packageContentContainer {
  display: flex;
  justify-content: center;
}
.packageContentWrapper {
  width: 80%;
  margin: 30px auto;
}
.packageContentText {
  font-size: 20px;
  line-height: 40px;
  text-align: left;
}

.packageImagesContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.packageImagesWrapper {
  width: 80%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}
.packageImages {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin-bottom: 60px;
  width: 100%;
}
.packageImage {
  width: 500px;
  /* height: 600px; */
  margin: 30px auto;
}
.pdfContiner {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.pdfDownload {
  text-decoration: none;
  margin: 30px;
  border: 3px solid #436b83;
  border-radius: 20px;
  padding: 10px 20px;
  color: black;
  font-size: 18px;
  font-weight: 800;
  letter-spacing: 2px;
}
.pdfDownload:hover {
  text-decoration: none;
}
/* our work */
.title {
  font-size: 60px;
  font-weight: 800;
  letter-spacing: 2px;
  margin: 30px auto;
}
.beforeImage,
.afterImage {
  display: flex;
  flex-direction: column;
  justify-content: top;
  align-items: center;
}
.imageTitle {
  font-size: 30px;
  font-weight: 800;
  letter-spacing: 2px;
}

.imageDesc {
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 2px;
}
.viewOthers {
  margin-top: 30px;
  width: 200px;
  border-radius: 50px;
  font-size: 16px;
  height: 60px;
  color: black;
  background: none;
}
/* contact us button */
.contactUs {
  width: 200px;
  border-radius: 50px;
  font-size: 16px;
  height: 60px;
  color: white;
  background: #25d366;
  bottom: 10px;
  right: 10px;
  font-weight: 800;
  letter-spacing: 2px;
}

/*  */
/**************************MOBILE VIEW*****************************************/
@media screen and (max-width: 580px) {
  .homepageWrapper {
    top: -140px;
  }
  .homepageDroneBgImage {
    height: 80vh;
    object-fit: cover;
    position: absolute;
  }
  .homepageHeaderContentTitle {
    font-size: 50px;
    line-height: 55px;
  }
  .homepageHeaderContentText {
    font-size: 20px;
    line-height: 25px;
  }
  .homepageGridContainer {
    margin-top: 0;
    justify-content: center;
    align-items: center;
  }
  .homepageGridWrapper {
    align-items: center;
    justify-content: center;
    width: 90%;
  }
  .homepageGridTitle {
    font-size: 40px;
    margin-bottom: 30px;
    margin-top: 0;
  }
  .homepageGridItem {
    width: 90%;
    margin: auto auto 10px auto;
  }
  .homepageGridItemImage {
    width: 350px;
    object-fit: cover;
  }
  .homepageBannerContainer {
    height: 400px;
    margin: 60px auto;
  }
  .homepageBannerWrapper {
    height: 400px;
  }
  .homepageBannerText {
    font-size: 40px;
    width: 90%;
  }
  .homepage3dQuarryWrapper {
    width: 90%;
  }
  .homepage3dQuarryTitle {
    font-size: 40px;
    text-align: center;
  }
  .contactUsInfoContainer {
    flex-direction: column;
  }
  .contactUsInfoWrapper {
    width: 90%;
  }
  .googleMaps {
    width: 90%;
  }
  .getQuoteButton {
    width: 170px;
    font-size: 12px;
    height: 50px;
    bottom: 10px;
    right: 10px;
  }

  /* Packages */
  .packagesTitleContainer {
  }
  .packagesTitle {
    font-size: 40px;
  }
  .packagesText {
    font-size: 25px;
  }
  .package .dropDown {
    margin-bottom: 40px;
  }
  .dropDownAgain {
    flex-direction: column;
    align-items: center;
  }
  .dropDownContainer {
    width: 90%;
  }
  .packageButtons {
    flex-direction: column;
  }
  .getQuote {
    margin-bottom: 20px;
  }
  .cardContainer {
    flex-direction: column;
  }
  .cardWrapper {
    width: 90%;
  }
  .packageImages {
    flex-direction: column;
    /* width: 90%; */
  }
  .beforeImage,
  .afterImage {
    margin-bottom: 30px;
  }
  .packageImage {
    width: 100%;
    /* height: 100px; */
  }
  .packageOneTitle {
    font-size: 40px;
    line-height: 50px;
  }
  .packageContentText {
    line-height: 30px;
  }
  .title {
    font-size: 40px;
    line-height: 60px;
  }
  .packageGrid {
    flex-direction: column;
    width: 80%;
  }
  .packageImageTwo {
    width: 350px;
    margin-bottom: 30px;
  }
}
