.sampleDroneMapsContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.titleText {
  font-size: 60px;
  text-align: center;
  font-weight: 800;
  margin: 50px 5px;
}

.sampleDroneMapsImagesContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  /* width: 80%; */
}

.sampleDroneMapsImageWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 70%;
  margin-bottom: 80px;
}

.sampleDroneMapsImageTitle {
  font-size: 30px;
  font-weight: 300;
  margin-bottom: 30px;
}
.sampleDroneMapsImage {
  width: 100%;
  border-radius: 25px;
}

.sampleDroneMapsGrid {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 90%;
  margin-bottom: 60px;
  text-align: center;
}

.sampleDroneMapsMeasurements {
  text-align: center;
}

.measurementsContainer {
  display: flex;
  justify-content: space-evenly;
}

.measurement {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 50%;
}

.measurementImage {
  width: 80%;
  object-fit: cover;
  margin-bottom: 30px;
}

/* Mobile View */
@media screen and (max-width: 580px) {
  .sampleDroneMapsContainer {
    margin-top: 50px;
  }
  .titleText {
    font-size: 40px;
    width: 70%;
  }
  .sampleDroneMapsImageWrapper {
    width: 90%;
  }
  .sampleDroneMapsGrid {
    flex-direction: column;
  }
  .measurementsContainer {
    justify-content: center;
    align-items: center;

    flex-direction: column;
  }
  .measurement {
    width: 90%;
  }
  .measurementImage {
    margin-bottom: 10px;
  }
  .measurementText {
    margin-bottom: 60px;
  }
}
