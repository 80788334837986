.googleImageryHeaderContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.googleImageryHeaderWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 80%;
}

.googleImageryHeaderTitle {
  font-size: 60px;
  font-weight: 800px;
  margin: 40px auto 30px auto;
}
.googleImageryHeaderText {
  font-size: 20px;
  width: 60%;
  line-height: 28px;
  margin-bottom: 60px;
}

/* iframe */
.iFrameContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.iFrameWrapper {
  width: 80%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.iFrameTitle {
  font-size: 30px;
  margin: 60px auto 30px auto;
}
.iFrameMap {
  width: 90%;
  height: 600px;
}
@media screen and (max-width: 580px) {
  .googleImageryHeaderContainer {
    width: 100%;
    margin-top: 60px;
  }
  .googleImageryHeaderWrapper {
    width: 80%;
    justify-content: center;
  }
  .googleImageryHeaderTitle {
    font-size: 40px;
    margin: 50px auto 20px auto;
  }
  .googleImageryHeaderText {
    width: 90%;
  }
  .iFrameWrapper {
    width: 90%;
  }
}
