.getQuoteContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.getQuoteWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.getQuoteTitle {
  width: 80%;
  font-size: 40px;
  text-align: center;
  margin: 60px auto 60px auto;
  line-height: 55px;
  font-weight: 800;
}

.getQuoteInstuctionList {
  /* width: 60%; */
  line-height: 70px;
  font-size: 30px;
}

.getQuoteContact {
  font-size: 25px;
  line-height: 31px;
  margin: 30px auto 30px auto;
  font-weight: 200;
}

.contactButton {
  display: flex;
  width: 50%;
  justify-content: space-evenly;
  margin-bottom: 60px;
}
.getQuoteVideo {
  display: flex;
  flex-direction: column;
  text-align: center;
  width: 80%;
}

.getQuoteVideoText {
  font-size: 30px;
  margin-bottom: 30px;
}

.getQuoteHr {
  width: 60%;
  margin-bottom: 60px;
}
.video {
  width: 800;
  height: 600;
}

@media screen and (max-width: 580px) {
  .getQuoteContainer {
    margin-top: 50px;
  }
  .getQuoteWrapper {
    width: 90%;
  }
  .getQuoteTitle {
    width: 100%;
    font-size: 30px;
    line-height: 45px;
  }
  .getQuoteInstuctionList {
    line-height: 45px;
    font-size: 25px;
  }
  .contactButton {
    width: 90%;
    justify-content: space-between;
  }
  .getQuoteVideo {
    width: 90%;
  }
}
